import React, { Suspense } from 'react'
import RequireUser from './contexts/RequireUser'; 
import FullScreenLoader from "./components/FullScreenLoader"

import { Route, Routes } from 'react-router-dom';
import Login from "./pages/CommonPages/Login"
import NotFoundPage from "./pages/CommonPages/NotFoundPage"

// index.js sau App.js

const UserPage = React.lazy(() => import('./pages/Admin/UserPage'));
const HomePage = React.lazy(() => import('./pages/HomePage'));
const OneCar = React.lazy(() => import('./pages/OneCar'));
const AdminHome = React.lazy(() => import('./pages/Admin/AdminHome'));
const Common = React.lazy(() => import('./components/Common'));
const ProfilPage = React.lazy(() => import('./pages/ProfilPage'));
const UserManage = React.lazy(() => import('./pages/Admin/UsersManage'));
const About = React.lazy(() => import('./pages/About'));
const FAQ = React.lazy(() => import('./pages/Faq'));
const Contacts = React.lazy(() => import('./pages/Contacts'));
const Ads = React.lazy(() => import('./pages/Admin/Ads'));


const App = () => {

  return (
    <div>
      <Suspense fallback={<FullScreenLoader />}>
      <Routes>
        <Route>
        <Route path="/404error" element={<NotFoundPage />} />
        <Route path="/login" element={<Login />} />
        <Route path='/' element={<Common />}>
          <Route element={<RequireUser allowedRoles={[1, 2]} />}>
                  <Route path="admin/bids" element={<AdminHome />} />
                  <Route path="admin/users" element={<UserManage />} />
                  <Route path="admin/ads" element={<Ads />} />
                  <Route path="admin/users/:user_id" element={<UserPage />} />
          </ Route>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/cars/:car_id" element={<OneCar />} />
          <Route path="/profil/:user_id" element={<ProfilPage />} />
        </Route>
        </Route>
      </Routes>
      </Suspense>
    </div>
  );
}

export default App;